<template>
  <v-container fill-height fluid>
    <v-row align="center">
      <v-col>
        <v-card
          class="mx-auto"
          max-width="1000"
          min-height="565"
          align="right"
          :img="getBackground"
        >
          <v-card
            max-width="350"
            min-height="565"
            align="center"
            class="rounded-l-0"
            flat
          >
            <v-container>
              <v-img
                src="@/assets/biodf/BioDF-textlogo.png"
                max-height="100"
                max-width="200"
              >
              </v-img>
            </v-container>
            <slot></slot>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginCard',
  computed: {
    getBackground() {
      const pictures = [
        'https://aaqr.org/images/article_images/2019/feature/18-12-0467.jpg',
        'https://thumbor.forbes.com/thumbor/960x0/https%3A%2F%2Fspecials-images.forbesimg.com%2Fdam%2Fimageserve%2F1148301788%2F960x0.jpg%3Ffit%3Dscale',
        'https://images.immediate.co.uk/production/volatile/sites/4/2018/07/iStock_000021655524_Large-a2de642.jpg?webp=true&quality=90&resize=940%2C399',
        'https://images.pexels.com/photos/3854025/pexels-photo-3854025.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        'https://images.pexels.com/photos/87771/animal-blue-creature-danger-87771.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        'https://images.pexels.com/photos/2765872/pexels-photo-2765872.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
      ]
      const random = Math.floor(Math.random() * pictures.length)
      return pictures[random]
    }
  }
}
</script>
