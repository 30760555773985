<template>
  <v-container>
    <!-- Login Form -->
    <v-form
      class="my-12"
      v-if="showLogin"
      @submit.prevent="onLogin"
      v-model="isLoginFormValid"
    >
      <p>Login to your account</p>
      <v-text-field
        v-model="email"
        label="Email"
        class="mx-2"
        data-testid="email"
        outlined
        clearable
      >
      </v-text-field>

      <v-text-field
        v-model="password"
        label="Password"
        class="mx-2"
        data-testid="password"
        outlined
        clearable
        :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="() => (visible = !visible)"
        :type="visible ? 'text' : 'password'"
        :rules="[validateNotEmpty]"
      >
      </v-text-field>

      <p align="right">
        <router-link
          :to="{ name: 'ResetPassword' }"
          data-testid="forgot-password"
        >
          Forgot password?
        </router-link>
      </p>

      <v-btn
        elevation="6"
        large
        color="primary"
        type="submit"
        :disabled="!isLoginFormValid"
        data-testid="login"
      >
        Login
      </v-btn>
    </v-form>

    <!--2FA form -->
    <v-form
      ref="authForm"
      v-if="show2FA"
      @submit.prevent="onCodeEntered"
      v-model="is2FAFormValid"
    >
      <p class="mt-8"><b>Two-Factor Authentication (2FA)</b></p>
      <div class="mt-16 mx-3">
        <p v-if="mfaType == 'SMS_MFA'">
          Please enter the 2FA code sent to your mobile device:
        </p>
        <p v-else-if="mfaType == 'SOFTWARE_TOKEN_MFA'">
          Please enter the 2FA code from your authenticator app:
        </p>
        <p v-else>
          Please enter the 2FA code from your mobile device:
        </p>

        <v-text-field
          class="mt-8"
          label="2FA Code*"
          v-model="authCode"
          outlined
          clearable
          :rules="[validateIsNumber]"
        >
        </v-text-field>

        <div style="margin-top: 74px;">
          <v-btn
            text
            @click="backToLogin"
            large
            elevation="6"
            class="mx-2 mb-4"
          >
            Back
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            large
            :disabled="!is2FAFormValid"
            elevation="6"
            class="mb-4"
          >
            Verify
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import UserPool from "@/plugins/UserPool";
import {
  validateNotEmpty,
  validateIsNumber,
} from "@/utilities/validationRules";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

export default {
  name: "LoginDefault",
  data: () => {
    return {
      // visual state
      visible: false,
      showLogin: true,
      show2FA: false,
      // login data
      email: "",
      password: "",
      rememberMe: false,
      isLoginFormValid: false,
      // 2FA data
      authCode: "",
      mfaType: "",
      is2FAFormValid: false,
      // reference to user so we don't have to authenticate the user twice
      user: {
        type: CognitoUser,
      },
    };
  },
  methods: {
    validateNotEmpty,
    validateIsNumber,
    backToLogin() {
      this.showLogin = true;
      this.show2FA = false;
      this.$refs.authForm.reset();
    },
    onLogin() {
      this.$emit("testOnLogin");

      this.user = new CognitoUser({
        Username: this.email,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: this.email,
        Password: this.password,
      });

      this.user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("Login sucess: ", data); // DEBUG
          localStorage.setItem("authenticated", "true");
          this.$router.push({ name: "Dashboard" });
        },
        onFailure: (err) => {
          console.error("Login failed: ", err); // DEBUG
          const notification = {
            type: "error",
            message: err.message,
          };
          this.$store.dispatch("addNotification", notification);
        },
        newPasswordRequired: (data) => {
          console.log("New password required: ", data); // DEBUG
          const notification = {
            type: "error",
            message: "New password required",
          };
          this.$store.dispatch("addNotification", notification);
        },
        mfaRequired: (challengeName, challengeParameters) => {
          console.log(challengeName);
          console.log(challengeParameters);
          // show the 2FA form
          this.showLogin = false;
          this.show2FA = true;
          this.mfaType = challengeName;
        },
        totpRequired: (challengeName, challengeParameters) => {
          console.log(challengeName);
          console.log(challengeParameters);
          // show the 2FA form
          this.showLogin = false;
          this.show2FA = true;
          this.mfaType = challengeName;
        },
      });
    },
    onCodeEntered() {
      this.user.sendMFACode(
        this.authCode,
        {
          onSuccess: (session) => {
            // mark user as authenticated and log them in
            localStorage.setItem("authenticated", "true");
            this.$router.push({ name: "Dashboard" });
          },
          onFailure: (err) => {
            console.log(err);
            const notification = {
              type: "error",
              message: err.message,
            };
            this.$store.dispatch("addNotification", notification);
          },
        },
        this.mfaType
      );
    },
  },
};
</script>
