<template>
  <div class="bg">
    <LoginCard>
      <LoginDefault />
    </LoginCard>
  </div>
</template>

<script>
import LoginCard from '@/components/login/LoginCard';
import LoginDefault from '@/components/login/LoginDefault';

export default {
  name: 'Login',
  components: {
    LoginCard,
    LoginDefault,
  },
};
</script>

<style scoped>
.bg {
  background-color: #0c5c7e;
  height: 100%;
}
</style>
